import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Pipe({
  name: 'safe',
  standalone: true,
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(
    value: any,
    type: string,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    // Return empty string if value is null or undefined
    if (!value) {
      return '';
    }

    // Basic XSS protection - remove potentially dangerous tags and attributes
    if (type === 'html') {
      value = this.sanitizeHtml(value);
    }

    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }

  private sanitizeHtml(html: any): string {
    // Handle null/undefined
    if (!html) {
      return '';
    }

    // Handle object with rich_text property
    if (typeof html === 'object' && 'rich_text' in html) {
      html = html.rich_text;
    }

    // Convert to string if not already
    if (typeof html !== 'string') {
      html = String(html);
    }

    // Remove potentially dangerous tags
    let sanitized = html.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      '',
    );
    sanitized = sanitized.replace(
      /<iframe\b[^<]*(?:(?!<\/iframe>)<[^<]*)*<\/iframe>/gi,
      '',
    );
    sanitized = sanitized.replace(
      /<object\b[^<]*(?:(?!<\/object>)<[^<]*)*<\/object>/gi,
      '',
    );
    sanitized = sanitized.replace(
      /<embed\b[^<]*(?:(?!<\/embed>)<[^<]*)*<\/embed>/gi,
      '',
    );

    // Remove dangerous attributes
    sanitized = sanitized.replace(/on\w+="[^"]*"/g, '');
    sanitized = sanitized.replace(/on\w+='[^']*'/g, '');

    // Remove javascript: URLs
    sanitized = sanitized.replace(
      /href\s*=\s*["']?\s*javascript:/gi,
      'href="removed"',
    );

    return sanitized;
  }
}
